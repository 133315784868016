export default {
  data () {
    return {
      isShowDocumentErrors: false
    }
  },
  computed: {
    $_requiredValidation () {
      return [val => !!val || 'Required']
    },
    $_zeroValidation () {
      return [val => (val !== null && val !== undefined) || this.$t('message.common.required')]
    },
    $_qtyValidation () {
      return [
        val => !!val || this.$t('message.common.required'),
        val => (val && val > 0) || this.$t('message.common.required')
      ]
    },
    $_multiSelectValidation () {
      return [
        val => !!val || 'Required',
        val => (val && val.length > 0) || 'Required'
      ]
    },
    $_HourValidation () {
      return [
        val => !!val || 'Required',
        val => this.$formatter.replaceCommaWithDot(val) <= 24 || 'Invalid Hours'
      ]
    },
    $_emailAndRequiredValidation () {
      return [
        (v) => !!(v) || 'Required',
        (v) => v ? /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/.test(v) || 'Invalid Email Address' : true
      ]
    },
    $_numberAndRequiredValidation () {
      return [
        (v) => !!(v) || 'Required',
        val => (val) ? /^[0-9.,]+$/.test(val) || 'Not Valid Number' : true,
        val => (val) ? !isNaN(this.$formatter.replaceCommaWithDot(val)) || 'Not Valid Number' : true
      ]
    },
    $_numberValidation () {
      return [
        val => (val) ? /^[0-9]+$/.test(val) || 'Not Valid Number' : true,
        val => (val) ? !isNaN(this.$formatter.replaceCommaWithDot(val)) || 'Not Valid Number' : true
      ]
    },
    $_norwegianNumberValidation () {
      return [
        val => (val) ? /^[0-9.,]+$/.test(val) || 'Not Valid Number' : true,
        val => (val) ? !isNaN(this.$formatter.replaceCommaWithDot(val)) || 'Not Valid Number' : true
      ]
    },
    $_norwegianNumberMinusValidation () {
      return [
        val => (val) ? /^-?[0-9.,]+$/.test(val) || 'Not Valid Number' : true,
        val => (val) ? !isNaN(this.$formatter.replaceCommaWithDot(val)) || 'Not Valid Number' : true
      ]
    },
    $_emailValidation () {
      return [
        (v) => v ? /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/.test(v) || 'Invalid Email Address' : true
      ]
    },
    $_discountValidation () {
      return [
        (val) => val ? (this.$formatter.replaceCommaWithDot(val) <= 100 && this.$formatter.replaceCommaWithDot(val) >= 0) || 'Invalid Number' : true
      ]
    },
    $_numberLength () {
      return [
        (val) => val ? (val && val.length <= 4) || this.$t('message.common.maxCharacters', { charCount: 4 }) : true
      ]
    },
    $_fieldNameValidation () {
      return [
        (v) => v ? /^[a-zA-Z0-9_]*$/.test(v) || 'Invalid name' : true
      ]
    },
    $_columnValidation () {
      return [
        v => !!v || 'Required',
        v => (v <= 12 && v >= 1) || 'Enter columns b/w 1 to 12'
      ]
    },
    $_accountType () {
      return [
        { text: 'Account', value: 'account' },
        { text: 'Lead', value: 'lead' },
        { text: 'Customer', value: 'customer' },
        { text: 'Supplier', value: 'supplier' }
      ]
    }
  },
  methods: {
    $_maxFileSizeValidation (maxSize, files) { // maxSize in mb so convert file size to mb
      let isFileSizeExceeded = false
      if (files && files.length > 0) {
        files.forEach(file => {
          const fileSize = (file.size / (1024 * 1024)).toFixed(2)
          if (fileSize > maxSize) {
            isFileSizeExceeded = true
            this.isShowDocumentErrors = true
          } else this.isShowDocumentErrors = false
        })
      } else this.isShowDocumentErrors = false
      return [
        isFileSizeExceeded ? this.$t('message.customModule.fileSizeLimitMsg', { maxSize }) : true
      ]
    }
  }
}
