import axios from 'axios'
import VueCookie from 'vue-cookie'
const api = axios.create({
  baseURL: process.env.VUE_APP_URL,
  headers: {
    common: {
      Authorization: `Bearer ${VueCookie.get(process.env.VUE_APP_TOKEN)}`
    }
  }
})

const checklistApi = axios.create({
  baseURL: process.env.VUE_APP_CHECKLIST_URL,
  headers: {
    common: {
      'X-AccessToken': VueCookie.get(process.env.VUE_APP_CHECKLIST_ACCESS_TOKEN),
      'x-auth-token': process.env.VUE_APP_CHECKLIST_AUTH_TOKEN ?? ''
    }
  }
})
const hostAppApi = axios.create({
  headers: {
    common: {
      Authorization: `Bearer ${VueCookie.get(process.env.VUE_APP_HOST_TOKEN)}`
    }
  }
})

// eslint-disable-next-line
const documentUrl = process.env.VUE_APP_DOCUMENT_URL
// eslint-disable-next-line
export { api, checklistApi, documentUrl, hostAppApi }
