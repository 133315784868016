import { api, hostAppApi } from './axios_settings'
export default {
  install (Vue, options) {
    Vue.prototype.$hostAppApi = {
      execute (method, url, model) {
        return hostAppApi[method](url, model)
      }
    }
    Vue.prototype.$api = {
      execute (method, url, model) {
        return api[method](url, model)
      },
      getSingleRecordHandler (url) {
        return this.execute('get', url)
      },
      getSingleHandler (url) {
        return new Promise((resolve, reject) => {
          this.execute('get', url).then(response => {
            resolve(response.data)
          }).catch((error) => {
            reject(error)
          })
        })
      },
      saveUpdateHandler (urlVal, model) {
        return new Promise((resolve, reject) => {
          const method = model.id ? 'put' : 'post'
          const url = model.id ? `${urlVal}/${model.id}` : urlVal
          const text = model.id ? 'message.common.updatedSuccess' : 'message.common.addedSucess'
          this.execute(method, url, model)
            .then(response => {
              response.data = { ...response.data, ...{ snackbar: { snackbar: true, color: 'success', text: text } } }
              resolve(response.data)
            })
            .catch((error) => {
              reject(error)
            })
        })
      },
      queryHandler (model, url) {
        const pageVal = model.page || 1
        const queries = [
          { ...model.match },
          { $sort: { [model.sortBy]: model.sortDesc ? -1 : 1 } },
          {
            $facet: {
              data: [{ $skip: ((pageVal - 1) * model.itemsPerPage) }, { $limit: model.itemsPerPage }],
              count: [{ $count: 'count' }]
            }
          }
        ]
        if (!model.search) queries.splice(0, 1)
        return new Promise((resolve, reject) => {
          this.execute('post', url, queries).then(response => {
            resolve(response.data)
          }).catch((error) => {
            reject(error)
          })
        })
      }
    }
  }
}
