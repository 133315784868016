const ccsheet = [{
  path: '/cycle_count',
  component: () => import('@/views/Warehouse/orders/ccsheet/View'),
  meta: { requiresAuth: true },
  children: [{
    path: '/',
    component: () => import('@/views/Warehouse/orders/ccsheet/List')
  }, {
    path: 'count/:sheet_id',
    name: 'count',
    component: () => import('@/views/Warehouse/orders/ccsheet/Count')
  }, {
    path: 'recount/:sheet_id/:has_edit',
    name: 'recount',
    component: () => import('@/views/Warehouse/orders/ccsheet/Recount')
  }]
}]
export default ccsheet
