import { LOGIN, LOGOUT } from './actionsTypes'
import { SET_AUTH, RESET_AUTH, SET_PERMISSIONS, RESET_PERMISSIONS } from './mutationsTypes'
import cookie from 'vue-cookie'
import { api, hostAppApi, checklistApi } from '../plugins/axios_settings'
const state = {
  isAuthenticated: false,
  showLoader: false,
  authToken: '',
  userDetails: {},
  role: null,
  permissions: null
}

const getters = {
  userDetails (state) {
    return state.userDetails
  },
  getuserDetails (state) {
    return state.userDetails
  },
  isAuthenticated (state) {
    return state.isAuthenticated
  }
}

const actions = {
  [LOGIN] (context, model) {
    var initialer = model.initialer
    delete model.initialer
    return new Promise((resolve, reject) => {
      api.post('auth', model).then(response => {
        var date = new Date()
        date.setDate(date.getDate() + 4)
        // eslint-disable-next-line camelcase
        const { id, name, email, role_id, token, timer_startup } = response.data
        const user = JSON.stringify({ id, name, email, role_id, initialer })
        var hostDate = new Date()
        hostDate.setDate(hostDate.getDate() + 30)
        cookie.set('AxentivPwa_host-ref', model.host, { expires: hostDate })
        cookie.set('TimerPwa_timerStartup', timer_startup, { expires: date })
        cookie.set(process.env.VUE_APP_TOKEN, token, { expires: date })
        cookie.set(process.env.VUE_APP_USER, user, { expires: date })
        api.defaults.headers.common.Authorization = `Bearer ${token}`
        hostAppApi.defaults.headers.common.Authorization = `Bearer ${model.hostAppToken}`
        checklistApi.defaults.headers.common['X-AccessToken'] = cookie.get(process.env.VUE_APP_CHECKLIST_ACCESS_TOKEN)
        context.commit(SET_AUTH, { authToken: token, user: JSON.parse(user) })
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  [LOGOUT] ({ commit }) {
    cookie.delete(process.env.VUE_APP_TOKEN)
    cookie.delete(process.env.VUE_APP_USER)
    commit(RESET_AUTH)
    /* remove host app cookies */
    cookie.delete(process.env.VUE_APP_HOST_TOKEN)
    cookie.delete(process.env.VUE_APP_HOST_USER)
    /* remove checklist access tokn */
    cookie.delete(process.env.VUE_APP_CHECKLIST_ACCESS_TOKEN)
    cookie.delete('AxentivPwa_host-ref')
    /* remove excludemodule cookie values */
    // cookie.delete('pwaAppExcludeModules')
    /* remove permission and role */
    cookie.delete(process.env.VUE_APP_PERMISSIONS_AND_ROLES)
    commit(RESET_PERMISSIONS)
    /* remove features on logout */
    /* set features of modules in localstorage */
    window.localStorage.removeItem(process.env.VUE_APP_FEATURES_LIST)
  }
}

const mutations = {
  [SET_AUTH] (state, user) {
    state.isAuthenticated = true
    state.authToken = user.authToken
    state.userDetails = user.user
  },
  [RESET_AUTH] (state) {
    state.isAuthenticated = false
    state.userDetails = {}
  },
  [SET_PERMISSIONS] (state, obj) {
    state.permissions = obj.permissions || null
    state.role = obj.role || null
  },
  [RESET_PERMISSIONS] (state) {
    state.permissions = null
    state.role = null
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
