import {
  PAGINATION, DELETE_HANDLER, DELETE_SINGLE_RECORD_HANDLER, USERS, WORKTYPES, ACTIVITIES, GETCURRENTPROJECTS, RELOAD_NEW_CONTENT, GET_RUNNING, CHECKLIST_FORMS, ACCOUNTS_LIST, USER_GROUPS,
  GENERAL_SETTINGS, CHECK_MONTHLOCKED_STATUS, GETALLTHE_LOGSFOR_SINGLEDAY, SHOWALLTHE_LOGSON_DAY
} from './actionsTypes'
import {
  SET_PAGINATION, SET_LISTVALUES, SHOW_DELETE_DIALOG, HIDE_DIALOG, SHOW_LOADER, HIDE_LOADER, REMEMBER_LIST, SHAREPPOINT_FIELDS,
  SET_TIMER_VALUE, CLEAR_TIMER_VALUE, SET_TIMEROBJ, SITE_NEW_CONTENT, IS_MONTHLOCKED, SHOWTOTAL_LOGSON_SINGLEDAY, SHOW_CONFIMATION_DIALOG, HIDE_CONFIMATION_DIALOG,
  SCANNED_VALUE
} from './mutationsTypes'
import { checklistApi, api, hostAppApi } from '../plugins/axios_settings'
import moment from 'moment'
import VueCookie from 'vue-cookie'

const state = {
  showDialog: false,
  deletePayload: {},
  showLoader: false,
  showConfirmationDialog: false,
  confirmationPayload: {},
  supportList: {},
  rememberOrder: {},
  FORM_TYPE: {
    TEXT: 'text',
    TEXTAREA: 'textarea',
    CHECKBOX: 'checkbox',
    SELECT: 'select',
    AUTO_COMPLETE: 'auto_complete',
    COMBOBOX: 'combobox',
    DATE: 'date',
    PASSWORD: 'password',
    NUMBER: 'number',
    DATEPICKER: 'datepicker',
    QUICKADD: 'quickadd',
    TIMEPICKER: 'timepicker',
    FILES: 'files',
    ALERT: 'alert',
    COLORPICKER: 'color',
    BUTTON: 'btn',
    BTNDROPDOWN: 'btnDropdown',
    RADIO: 'radio'
  },
  // List Pages
  USER_INDEX: {},
  CRM_INDEX: {},
  TENANTINDEX: {},
  COMMON_INDEX: {},
  TICKETSTATUS_INDEX: {},
  DOCUMENT_TEMPLATE_INDEX: {},
  TICKETTYPE_INDEX: {},
  PRIORITIES_INDEX: {},
  MAIL_CONFIG_INDEX: {},
  TICKETS_INDEX: {},
  ACCOUNT_TICKETS: {},
  PROJECT_ROLES_INDEX: {},
  MODULES_INDEX: {},
  PROPS_INDEX: {},
  FORM_PROPS_INDEX: {},
  // added by vinoth -->
  DEVIATION_INDEX: {},
  EQUIPMENT_INDEX: {},
  PROJECT_CUSTOM_MODULE_INDEX: {},
  TASKS_INDEX: {},
  // added by vinoth <--
  // Filters
  F_TICKETS: {},
  F_ACCOUNT_TICKETS: {},
  // Common Lists
  listOfUsers: [],
  listOfWorktypes: [],
  listOfActivities: [],
  listOfCurrentProjects: [],
  // GeneralSettings
  generalSettings: {},
  // Modal minimize
  sharepointFieldList: [],
  sharepointProperties: [],
  sharepointDynamicFields: [],
  // Stamp timer
  isTimerRunning: false,
  currentRunningTime: '',
  timerObj: {},
  // service worker
  isNewContentAvaliable: false,
  activeSW: {},
  listOfChecklistForms: [],
  listOfAccounts: [],
  listOfUserGroups: [],
  // Axentiv Timer
  host: process.env.VUE_APP_STORE_HOST_DOMAIN, // '.axentivtest.no', // '.axentivtest.no', // '.axentiv.no', // '.weldtest.no', .weldit.no, .axentiv.no, '.hms2go.no',
  isMonthLocked: false,
  // to show all the logs on single day
  totalListOfHourLogsOnDay: [],
  totalListOfNonBillableOnDay: [],
  totalHourLogs: 0,
  totalNonbillable: 0,
  getTimeLine: '',
  /* Project, Deviation exclude -for axentiv */
  isProjectExcluded: false,
  isDeviationExcluded: false,
  projectAttachmentHost: 'haaplan',
  notifiedCount: 0,
  standardStartTime: '',
  hasStampAttachments: false,
  isCalendarFormExcluded: false,
  isStempExcluded: false,
  includeGlobalChecklist: true,
  includeProjectChecklist: true,
  isEquipmentExcluded: false,
  includeEquipmentChecklist: true,
  includeProjectEquipment: true,
  isWarehouseExcluded: false,
  scannedCode: ''
}

const getters = {
  formType (state) {
    return state.FORM_TYPE
  },
  showDialog (state) {
    return state.showDialog
  },
  showConfirmationDialog (state) {
    return state.showConfirmationDialog
  },
  confirmationPayload (state) {
    return state.confirmationPayload
  },
  deletePayload (state) {
    return state.deletePayload
  },
  getUsers (state) {
    return state.listOfUsers
  },
  timerData (state) {
    return { isTimeRunning: state.isTimerRunning, runningTime: state.currentRunningTime }
  },
  getWorktypes (state) {
    return state.listOfWorktypes
  },
  getActivities (state) {
    return state.listOfActivities
  },
  getCurrentProjects () {
    return state.listOfCurrentProjects
  },
  showLoader (state) {
    return state.showLoader
  },
  listOfModules (state) {
    return state.listOfModules
  },
  listOfFields (state) {
    return state.sharepointFieldList
  },
  listOfProperties (state) {
    return state.sharepointProperties
  },
  getTimerObj (state) {
    return state.timerObj
  },
  getNewContentAvailable (state) {
    return state.isNewContentAvaliable
  },
  getAccountList (state) {
    return state.listOfAccounts
  },
  getUserGroups (state) {
    return state.listOfUserGroups
  },
  generalSettings (state) {
    return state.generalSettings
  },
  hasStampAttachments (state) {
    return state.hasStampAttachments
  },
  getHostRefApi (state) {
    let hostRef = VueCookie.get('AxentivPwa_host-ref') || ''
    if (!hostRef.includes(state.host)) {
      hostRef += state.host
    }
    return `https://${hostRef}/api/`
    // return hostRef
  },
  userId () {
    return VueCookie.get(process.env.VUE_APP_HOST_USER) ? parseInt(VueCookie.get(process.env.VUE_APP_HOST_USER)) : 0
  },
  getScannedCode (state) {
    return state.scannedCode
  }
}

const actions = {
  [PAGINATION] (context, paginationObj) {
    context.commit(SET_PAGINATION, paginationObj)
  },
  [USERS] ({ commit }) {
    return new Promise((resolve, reject) => {
      const model = [{
        $match: { IsActive: true }
      }]
      api.post('users/query', model)
        .then((response) => {
          response.data.forEach(element => {
            element.name = element.lastname ? `${element.firstname} ${element.lastname}` : `${element.firstname}`
          })
          const model = { type: 'listOfUsers', data: response.data }
          commit(SET_LISTVALUES, model)
          resolve()
        })
    })
  },
  [DELETE_HANDLER] ({ commit }, model) {
    return new Promise((resolve, reject) => {
      const modelObj = { data: { ids: model.ids } }
      api.delete(model.url, modelObj)
        .then(() => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  [DELETE_SINGLE_RECORD_HANDLER] ({ commit }, model) {
    return new Promise((resolve, reject) => {
      const deleteApi = model.fromHostApi ? hostAppApi : api
      let url = ''
      if (model.fromHostApi) {
        const hostRef = VueCookie.get('AxentivPwa_host-ref')
        url = `https://${hostRef}/api/${model.url}`
      } else url = model.url
      deleteApi.delete(url)
        .then(() => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  [USER_GROUPS] ({ commit }) {
    return new Promise((resolve, reject) => {
      const model = [{
        $project: { Name: 1, Description: 1, Is_Administrative_Level: 1 }
      }]
      api.post('usergroups/query', model)
        .then((response) => {
          const model = { type: 'listOfUserGroups', data: response.data }
          commit(SET_LISTVALUES, model)
          resolve()
        })
    })
  },
  [WORKTYPES] ({ commit }) {
    return new Promise((resolve, reject) => {
      api.get(`worktypes/get_by_user?id=${this.state.auth.userDetails.id}`)
        .then((response) => {
          const result = response.data.filter(x => x.active)
          const model = { type: 'listOfWorktypes', data: result }
          commit(SET_LISTVALUES, model)
          resolve()
        })
    })
  },
  [ACTIVITIES] ({ commit }) {
    return new Promise((resolve, reject) => {
      api.get('activities')
        .then((response) => {
          const model = { type: 'listOfActivities', data: response.data }
          commit(SET_LISTVALUES, model)
          resolve()
        })
    })
  },
  [GETCURRENTPROJECTS] ({ commit }, dateObj) {
    const date = moment(dateObj, 'DD.MM.YYYY').format('MM.DD.YYYY')
    return new Promise((resolve, reject) => {
      api.get(`projects/get_current_projects/?date=${date}`)
        .then((response) => {
          const model = { type: 'listOfCurrentProjects', data: response.data }
          commit(SET_LISTVALUES, model)
          resolve()
        })
    })
  },
  [GET_RUNNING] ({ commit }, userId) {
    return new Promise((resolve, reject) => {
      api.get(`stamptimes/get_running/${userId}`)
        .then(response => {
          if (response.data) {
            const model = response.data
            window.localStorage.setItem('startedTimeOfTimer', response.data.start_at)
            if (model && model.start_at) {
              const time = getCalculatedTime(model.start_at)
              const getModel = { time, ...model }
              resolve(getModel)
            }
          } else resolve()
        })
        .catch((ex) => {
          reject(ex)
        })
    })
  },
  [CHECKLIST_FORMS] ({ commit }, module) {
    return new Promise((resolve, reject) => {
      const query = [{ $match: { Is_Active: true } }]
      checklistApi.post('forms/query', query)
        .then((response) => {
          const model = { type: 'listOfChecklistForms', data: response.data }
          commit(SET_LISTVALUES, model)
          resolve()
        })
    })
  },
  [ACCOUNTS_LIST] ({ commit }, module) {
    return new Promise((resolve, reject) => {
      const query = [{ $match: { Is_Active: true } }]
      api.get('moduledata/Account', query)
        .then((response) => {
          const model = { type: 'listOfAccounts', data: response.data }
          commit(SET_LISTVALUES, model)
          resolve()
        })
    })
  },
  [RELOAD_NEW_CONTENT] ({ state, commit }) {
    state.activeSW.waiting.postMessage({ action: 'skipWaiting' })
    commit(RELOAD_NEW_CONTENT)
  },
  [GENERAL_SETTINGS] ({ commit }) {
    return new Promise((resolve, reject) => {
      api.get('generalsettings')
        .then((response) => {
          const generalSettings = response.data[0]
          /* the next 3 line - if values is null then set default value */
          if (!generalSettings.hour_enter_style) generalSettings.hour_enter_style = 1
          if (!generalSettings.pause_setting_type) generalSettings.pause_setting_type = 1
          if (!generalSettings.timer_startup) generalSettings.timer_startup = 2
          const model = { type: 'generalSettings', data: generalSettings }
          /* if hour_enter_style not set then in calendar add - hour adding field is missing fix this */
          commit(SET_LISTVALUES, model)
          /* check and update the project,deviation exclude module vaues in cookie */
          generalSettings.include_stamp_attachments = generalSettings.include_stamp_attachments ? generalSettings.include_stamp_attachments : false
          commit('staticStandardStartTime', generalSettings.standard_start_time)
          /* const projectModule = generalSettings.exclude_project_module
          const deviationModule = generalSettings.exclude_deviation_module */
          /* Set the values in state object and check to change for the cookie value */
          /* commit('isProjectExcluded', projectModule)
          commit('isDeviationExcluded', deviationModule) */

          /* local set for include stamp attachment, exclude_stemp_module, exclude_calendar_module */
          // generalSettings.include_stamp_attachments = false - it is implemented in api
          generalSettings.exclude_stemp_module = false
          generalSettings.exclude_calendar_form = false
          commit('hasStampAttachments', generalSettings.include_stamp_attachments ? generalSettings.include_stamp_attachments : false)
          // commit('isStempExcluded', generalSettings.exclude_stemp_module)
          // commit('isCalendarFormExcluded', generalSettings.exclude_calendar_form)

          /* const excludeModules = JSON.parse(VueCookie.get('pwaAppExcludeModules'))
          const projectNewValue = projectModule ? 1 : 0
          const deviationNewValue = deviationModule ? 1 : 0
          const newExcludeObj = JSON.parse(JSON.stringify(excludeModules))
          if (newExcludeObj) {
            if (excludeModules.project && excludeModules.project !== projectNewValue) {
              newExcludeObj.project = projectNewValue
            }
            if (excludeModules.deviation && excludeModules.deviation !== deviationNewValue) {
              newExcludeObj.deviation = deviationNewValue
            }
            VueCookie.set('pwaAppExcludeModules', JSON.stringify(newExcludeObj), { expires: newExcludeObj.expires_at })
          } */
          resolve()
        })
    })
  },
  [CHECK_MONTHLOCKED_STATUS] ({ commit, dispatch }, date) {
    var year = moment(date, 'DD.MM.YYYY').format('YYYY')
    var week = moment(date, 'DD.MM.YYYY').isoWeek()
    return new Promise((resolve, reject) => {
      api.get(`weeklock/is_week_locked?week=${week}&year=${year}`)
        .then(response => {
          commit('isMonthLocked', response.data)
          if (!response.data) { // if monthlocked is false then load list of projects
            dispatch('getCurrentProjects', date)
          }
          resolve(response.data)
        })
    })
  },
  [GETALLTHE_LOGSFOR_SINGLEDAY] ({ dispatch }, obj) {
    const start = obj.date // moment(obj.date, 'DD.MM.YYYY').format('MM.DD.YYYY')
    const end = moment(obj.date, 'MM.DD.YYYY').format('MM.DD.YYYY 23:59:59')
    const model = { start: start, end: end, users: [this.state.auth.userDetails.id], showAllProjects: true }
    api.post('hours/filter', model).then((response) => {
      var hoursList = response.data
      let totalOfWork = 0
      let totalOfAbsense = 0
      var listStartTime = []; var listOfEndTime = []
      for (var i = 0; i < hoursList.length; i++) {
        if (hoursList[i].type === 'working') {
          totalOfWork += hoursList[i].amount - hoursList[i].break_hr
        }
        if (hoursList[i].type === 'non_working') {
          totalOfAbsense += hoursList[i].amount
        }
        if (!isNaN(hoursList[i].break_hr)) {
          totalOfAbsense += hoursList[i].break_hr
        }
        const startDate = moment(hoursList[i].start_date, 'DD.MM.YYYY[T]HH.mm.ss').format('DD.MM.YYYY')
        const endDate = moment(hoursList[i].end_date, 'DD.MM.YYYY[T]HH.mm.ss').format('DD.MM.YYYY')
        if (startDate === endDate) {
          listStartTime.push(moment(hoursList[i].start_date, 'DD.MM.YYYY[T]HH.mm.ss').format('HH.mm'))
          listOfEndTime.push(moment(hoursList[i].end_date, 'DD.MM.YYYY[T]HH.mm.ss').format('HH.mm'))
        }
        hoursList[i].startingTime = Math.min(...listStartTime)
        hoursList[i].endingTime = Math.max(...listOfEndTime)
      }
      const start = isFinite(Math.min(...listStartTime)) ? Math.min(...listStartTime) : ''
      const end = isFinite(Math.max(...listOfEndTime)) ? Math.max(...listOfEndTime) : ''
      const timings = { start, end }
      var model = { logs: hoursList, totalHourLog: totalOfWork, totalNonbillable: totalOfAbsense, timings }
      dispatch('showAllTheLogsOnDay', model)
    })
  },
  [SHOWALLTHE_LOGSON_DAY] ({ commit }, model) {
    if (model.logs) {
      const hourEntries = model.logs.filter(x => x.type === 'working')
      const nonBillableEntries = model.logs.filter(x => x.type === 'non_working')
      var logs = { hours: hourEntries, nonBillable: nonBillableEntries, totalHourLog: model.totalHourLog, totalNonbillable: model.totalNonbillable, showTimeLine: model.timings }
      commit('showTotalLogsOnSingleDay', logs)
    }
  }
}

const mutations = {
  [SET_PAGINATION] (state, paginationObj) {
    state[paginationObj.type] = paginationObj.data
  },
  [SET_LISTVALUES] (state, obj) {
    state[obj.type] = obj.data
  },
  [SHAREPPOINT_FIELDS] (state, obj) {
    state.sharepointFieldList = obj.data
  },
  [REMEMBER_LIST]: (state, pagination) => {
    state[pagination.type] = pagination.data
  },
  [SHOW_DELETE_DIALOG]: (state, data) => {
    state.deletePayload = data
    state.showDialog = true
  },
  [HIDE_DIALOG] (state) {
    state.showDialog = false
  },
  [SHOW_CONFIMATION_DIALOG]: (state, data) => {
    state.confirmationPayload = data
    state.showConfirmationDialog = true
  },
  [HIDE_CONFIMATION_DIALOG]: (state) => {
    state.showConfirmationDialog = false
  },
  [SHOW_LOADER] (state) {
    state.showLoader = true
  },
  [HIDE_LOADER] (state) {
    state.showLoader = false
  },
  [SET_TIMER_VALUE] (state, time) {
    state.isTimerRunning = true
    state.currentRunningTime = time
  },
  [CLEAR_TIMER_VALUE] (state) {
    state.isTimerRunning = false
    state.currentRunningTime = ''
  },
  [SET_TIMEROBJ] (state, obj) {
    state.timerObj = obj
  },
  [SITE_NEW_CONTENT]: (state, data) => {
    state.activeSW = data
    state.isNewContentAvaliable = true
  },
  [RELOAD_NEW_CONTENT]: (state) => {
    state.isNewContentAvaliable = false
  },
  [IS_MONTHLOCKED]: (state, status) => {
    state.isMonthLocked = status
  },
  [SHOWTOTAL_LOGSON_SINGLEDAY]: (state, logs) => {
    state.totalListOfHourLogsOnDay = logs.hours
    state.totalListOfNonBillableOnDay = logs.nonBillable
    state.totalHourLogs = logs.totalHourLog
    state.totalNonbillable = logs.totalNonbillable
    state.getTimeLine = logs.showTimeLine
  },
  isProjectExcluded: (state, booleanValue) => {
    state.isProjectExcluded = booleanValue
  },
  isDeviationExcluded: (state, booleanValue) => {
    state.isDeviationExcluded = booleanValue
  },
  staticStandardStartTime: (state, standardStartTime) => {
    state.standardStartTime = standardStartTime
  },
  hasStampAttachments: (state, isIncluded) => {
    state.hasStampAttachments = isIncluded
  },
  isCalendarFormExcluded: (state, booleanValue) => {
    state.isCalendarFormExcluded = booleanValue
  },
  isStempExcluded: (state, booleanValue) => {
    state.isStempExcluded = booleanValue
  },
  [SCANNED_VALUE]: (state, obj) => {
    state.scannedCode = obj
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
// Calculate timer running in hours and minutes when user comeback
const getCalculatedTime = (startat) => {
  var current = moment()
  var startAt = moment(startat, 'DD.MM.YYYYTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss')
  var durationStartToCurrent = moment.duration(current.diff(startAt)).asHours()
  // DURATION B/W CURRENT AND START TIME - (DURATION B/W CURRENT AND START TIME)
  var difference = (durationStartToCurrent - (durationStartToCurrent))
  var finalTime = startAt.add(difference, 'hours').toDate()
  return finalTime
}
