export default {
  data () {
    return {
      features: {}
    }
  },
  created () {
    const features = window.localStorage.getItem(process.env.VUE_APP_FEATURES_LIST) || ''
    if (features) this.features = JSON.parse(this.$formatter.decryptString(features)) || {}
  },
  methods: {
    checkFeatureEnabled (name) {
      return this.features[name] ? Number(this.features[name].enabled) : true
    },
    getFeatureObj (name) {
      return this.features[name]
    },
    getCustomModules () {
      const custommodules = []
      const custommodulesList = this.$formatter.cloneVariable(this.features.custom_modules || '')
      if (custommodulesList && Object.keys(custommodulesList).length) {
        Object.values(custommodulesList).forEach(cModule => {
          if (cModule.enabled && cModule.link) custommodules.push({ icon: 'mdi-album', to: '', name: cModule.label, isCustomModules: true, link: cModule.link })
        })
      }
      return (custommodules && custommodules.length) ? custommodules : false
    }
  }
}
