export default {
  data () {
    return {
      projectObj: null
    }
  },
  computed: {
    printProjectName () {
      if (this.projectObj) return this.setProjectname(this.projectObj)
      else return ''
    }
  },
  methods: {
    getProject (id) { // project id
      this.$api.execute('get', `projects/${id}`).then(response => {
        if (response && response.data) this.projectObj = this.$formatter.cloneVariable(response.data)
      })
    },
    setProjectname (project) {
      let projectName = ''
      projectName += project.number ? `${project.number} - ` : ''
      projectName += project.po_name ? `${project.po_name} - ` : ''
      projectName += project.name ? project.name : ''
      return projectName // project.number ? `${project.number} - ${project.name}` : `${project.name}`
    },
    setEquimentName (equipment) {
      let equipmentName = ''
      equipmentName += equipment.equipment_number ? `${equipment.equipment_number} - ` : ''
      equipmentName += equipment.name ? equipment.name : ''
      return equipmentName
    }
  }
}
