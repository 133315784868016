<template>
  <div>
    <v-card flat class="pa-0">
      <v-card-text class="pa-1">
        <v-row class="mx-0">
          <v-spacer></v-spacer>
          <v-col cols="12" xs="12" sm="6" md="5" lg="4">
            <v-card class="pa-1 text-center headline" outlined tile>
              {{ currentDay }} {{ currentDate }}
            </v-card>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <!-- <v-row class="mx-0">
          <v-spacer></v-spacer>
          <v-col cols="12" xs="12" sm="6" md="5" lg="4" class="text-center headline">
            <v-fade-transition leave-absolute>
              <span :key="time">
                <span>{{ time }}</span>
              </span>
            </v-fade-transition>
          </v-col>
          <v-spacer></v-spacer>
        </v-row> -->
        <v-row class="mx-0 mb-5 mt-3 text-center">
            <v-col cols="12" align="center" class="pa-0">
              <template v-if="!timerData.isTimeRunning">
                <v-badge bordered color="success" icon="mdi-play" overlap class="mr-5">
                  <v-btn :color="isDisabledTimer || isWeekLocked ? '' : 'success'" :class="isDisabledTimer || isWeekLocked ? 'include_stamp_attachment' : ''" tile @click="start()" class="mr-2">{{$t('message.timer.start')}}</v-btn><!-- :disabled="isDisabledTimer" -->
                </v-badge>
              </template>
              <template v-else>
                <v-badge bordered color="success" icon="mdi-stop" overlap class="mx-2 mr-5">
                  <v-btn tile color="success" dark @click="stopTimer" class="mr-2"><span>{{ time }}</span></v-btn>
                </v-badge>
              </template>
              <template>
                <input
                  ref="uploader"
                  class="d-none"
                  type="file"
                  accept="image/*"
                  @change="onFileChanged"
                >
              </template>
              <template v-if="timerData.isTimeRunning">
                <v-tooltip bottom>
                  <template template v-slot:activator="{ on }">
                    <v-btn class="mx-1" v-on="on" fab dark small color="error" @click="showCancelDialog = true">
                      <v-icon dark>mdi-cancel</v-icon>
                    </v-btn>
                   </template>
                  <span>{{$t('message.timer.cancelTimer')}}</span>
                </v-tooltip>
              </template>
              <template v-for="(item, index) in quickNavItems">
                <v-tooltip bottom :key="`${index}_navItem`">
                  <template template v-slot:activator="{ on }">
                    <v-btn class="mx-1" v-on="on" fab dark small :color="item.color" @click="$router.push(item.to)">
                      <v-icon dark>{{item.icon}}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t(item.tooltipText) }}</span>
                </v-tooltip>
              </template>
            </v-col>
          <!-- <v-spacer></v-spacer>
          <v-col cols="12" sm="6" xs="12" md="5" lg="4" align="center">
            <v-btn  color="info" tile dark @click="$router.push('/calendar')" class="mx-1">{{$t('message.timer.ga_til_time')}}</v-btn>
            <v-btn  color="primary" tile dark @click="$router.push('/myshifts')" class="mx-1">{{$t('message.shifts.shift')}}</v-btn>
          </v-col> -->
        </v-row>
        <v-row class="mx-0">
          <v-spacer></v-spacer>
          <v-col cols="12" xs="12" sm="6" md="5" lg="4" class="pa-0">
            <form-data :references.sync="formReferences" :model="timerObj" ref="hourReference"></form-data>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row class="mx-0 mt-2">
          <v-flex class="xs4 xm3 lg2 pa-1" v-for="image in allAttachments" :key="image.id">
            <v-img width="100" height="50" :src="`${getBaseUrl}/Resources/${image.fileguid}`" @click="selectedImage = `${getBaseUrl}/Resources/${image.fileguid}`; showImageInFullScreen = true"></v-img>
          </v-flex>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="showImageInFullScreen">
      <v-img :src="selectedImage"></v-img>
    </v-dialog>
    <!-- DELETE WARNING -->
    <v-dialog v-model="showCancelDialog" persistent max-width="400px" class="mt-0">
      <v-card class="pa-0" flat>
        <v-card-title>
          {{ $t('message.common.confirmCancel') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-5">
          {{ $t('message.common.areYouSureCancel') }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" id="confirmCancel" :loading="cancelLoading" @click="confirmCancel">{{ $t('message.common.yes') }}</v-btn>
          <v-btn color="primary" id="closeCancel" @click="showCancelDialog=false">{{ $t('message.common.no') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DELETE WARNING  -->
  </div>
</template>
<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import TimerMixin from '../../mixins/timer'
import projectMixin from '@/mixins/projectMixin'
export default {
  mixins: [TimerMixin, projectMixin],
  data () {
    return {
      currentDay: moment().format('dddd'),
      currentDate: moment().format('DD.MM.YYYY'),
      time: '00:00:00',
      webWorkerInstance: null,
      timerObj: {
        project_id: 0
      },
      listOfActivities: [],
      isInitital: true,
      // getCurrentProjects: [],
      showCancelDialog: false,
      cancelLoading: false,
      listOfbreaks: [{ val: 0 }, { val: 15 }, { val: 30 }, { val: 45 }, { val: 60 }],
      quickNavItems: [
        { icon: 'mdi-calendar-clock', to: '/calendar', tooltipText: 'message.timer.ga_til_time', color: 'info' },
        { icon: 'mdi-calendar-text-outline', to: '/myshifts', tooltipText: 'message.shifts.shift', color: 'primary' }
      ],
      isPictureTakenBefore: false,
      allAttachments: [],
      fileUploadType: 0, // 1-on start , 2- on stop,  3- on store
      selectedImage: '',
      showImageInFullScreen: false,
      reloadApp: false
    }
  },
  components: {
    'form-data': () => import('@/components/FormTemplate')
  },
  computed: {
    ...mapGetters(['getuserDetails', 'formType', 'getWorktypes', 'timerData', 'generalSettings', 'hasStampAttachments', 'getCurrentProjects']),
    formReferences () {
      return {
        cardFlat: false,
        properties: [{
          model: 'project_id',
          type: this.formType.AUTO_COMPLETE,
          rules: this.$_requiredValidation,
          items: this.getCurrentProjects,
          select_text: (item) => this.setProjectname(item),
          select_value: 'id',
          label: this.$t('message.layout.projects'),
          class: 'xs12',
          is_show: this.$store.state.common.isProjectExcluded ? this.setDefaultProject() : true,
          disabled: this.isDisabledTimer || this.isWeekLocked
        }, {
          model: 'break_hr',
          type: this.formType.SELECT,
          rules: [], // this.$_requiredValidation,
          items: this.listOfbreaks,
          select_text: (item) => item.val + ` ${this.$t('message.timer.minutes')}`,
          select_value: 'val',
          disabled: this.isDisabledTimer || this.isWeekLocked,
          label: this.$t('message.timer.pause'),
          class: 'xs12',
          is_show: this.getPauseSettingType === 3,
          change: this.saveItem
        }, {
          model: 'activity_id',
          type: this.formType.AUTO_COMPLETE,
          rules: this.$store.state.common.isProjectExcluded ? [] : this.$_requiredValidation,
          items: this.listOfActivities,
          is_list: false,
          click: (search) => false,
          term: '',
          change: this.saveItem,
          select_text: 'name',
          select_value: 'id',
          label: this.$t('message.timer.activity'),
          class: 'xs12',
          disabled: this.isDisabledTimer || this.isWeekLocked
        }, {
          model: 'work_id',
          type: this.formType.AUTO_COMPLETE,
          rules: this.$_requiredValidation,
          items: this.getWorktypes.filter(x => x.is_billable),
          is_list: false,
          click: (search) => false,
          term: '',
          change: this.saveItem,
          select_text: (val) => `${val[`${this.$i18n.locale}_name`]}`,
          select_value: 'id',
          label: this.$t('message.common.worktype'),
          class: 'xs12',
          disabled: this.isDisabledTimer || this.isWeekLocked
        }, {
          model: 'location',
          type: this.formType.TEXT,
          rules: [],
          change: this.saveItem,
          label: this.$t('message.timer.place'),
          class: 'xs12',
          disabled: this.isDisabledTimer || this.isWeekLocked
        }, {
          model: 'comments',
          type: this.formType.TEXTAREA,
          rules: this.$_requiredValidation,
          change: this.saveItem,
          label: this.$t('message.common.comments'),
          class: 'xs12',
          rows: 3,
          disabled: this.isDisabledTimer || this.isWeekLocked
        }, {
          model: 'files',
          type: this.formType.FILES,
          rules: [],
          accept: 'image/*',
          change: this.storeFileChange,
          label: this.$t('message.deviation.upload_files'),
          class: 'xs12',
          is_show: this.hasStampAttachments,
          disabled: this.isDisabledTimer || this.isWeekLocked
        }],
        buttons: []
      }
    },
    getPauseSettingType () {
      return this.generalSettings.pause_setting_type
    },
    isDisabledTimer () {
      if (this.hasStampAttachments) return (this.getCurrentProjects.length === 0 || !this.isPictureTakenBefore)
      return this.getCurrentProjects.length === 0
    },
    isShowProjectField () {
      if (this.$store.state.common.isProjectExcluded) { /*  && this.isTimerRunnning */
        if (this.getCurrentProjects.length > 0) {
          const obj = this.getCurrentProjects && this.getCurrentProjects.length > 0 ? this.getCurrentProjects.find(x => x.number === 'TM001') : null
          if (obj) {
            if (!this.timerObj.project_id) this.saveProject(obj.id)
          }
        }
      }
      return !this.$store.state.common.isProjectExcluded
    },
    getBaseUrl () {
      let url = process.env.VUE_APP_URL
      url = url.split('/api')[0]
      return url
    },
    isWeekLocked () {
      return this.$store.state.common.isMonthLocked
    }
  },
  async beforeRouteEnter (to, from, next) {
    await next(vm => {
      if (from && from.path === '/login') vm.reloadApp = true
    })
  },
  created () {
    this.$store.dispatch('getGeneralSettings').then(() => {
      if (this.$store.state.common.isStempExcluded) this.$router.push('/')
    })
    const date = moment().format('DD.MM.YYYY')
    this.checkWeekLock(date)
    this.$store.dispatch('getWorktypes').then(() => {
      const result = this.getWorktypes.find(x => x.is_default && x.is_billable)
      if (result) this.timerObj.work_id = result.id
      // this.getProjects()
    })
    this.$eventBus.$on('languageChanged', (locale) => {
      locale = locale === 'no' ? 'nb' : 'en'
      this.currentDay = moment().locale(locale).format('dddd')
      this.currentDate = moment().locale(locale).format('DD.MM.YYYY')
    })
  },
  watch: {
    reloadApp (val) {
      if (val) this.$router.go()
    },
    'timerObj.project_id' (val) {
      if (val && val !== 0) {
        /*  save user_id * project_id in localstorage #7601-pin the project for preselect on next login */
        const pinProject = JSON.stringify({ user_id: this.getuserDetails.id, project_id: val })
        window.localStorage.setItem('stempPinnedProject', pinProject)
        /* #7601 ends */
        this.saveProject(val, false)
        this.getActivitiesForProject(val)
      } else this.listOfActivities = []
    }
  },
  mounted () {
    this.timerObj.user_id = this.getuserDetails.id
    this.getRunningStemp()
  },

  methods: {
    checkWeekLock (date) {
      this.$store.dispatch('checkMonthLockedStatus', date).then((response) => { // true or false value
        if (response) this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.calendar.dayLocked' })// notification show for month locked
      })
    },
    getProjects () {
      const date = moment().format('MM.DD.YYYY')
      this.$api.execute('get', `projects/get_current_projects?date=${date}`).then((response) => {
        if (response && response.data.length > 0) {
          this.getCurrentProjects = this.$formatter.cloneVariable(response.data)
        }
      })
    },
    getActivitiesForProject (projectId) {
      this.$api.execute('get', `activities/get_by_project/${projectId}`)
        .then(response => {
          this.listOfActivities = this.$formatter.cloneVariable(response.data)
        })
    },
    start () {
      if (this.isWeekLocked) {
        this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.calendar.dayLocked' })
        return
      }
      if (this.getCurrentProjects.length === 0) {
        this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.projects.noActiveProjects' })
        return
      }
      if (this.hasStampAttachments) {
        this.fileUploadType = 1
        this.$refs.uploader.click()
      } else this.startTimer('')
      /* if (this.$refs.hourReference.$refs.validateForm.validate()) {
      } */
    },
    stopTimer () {
      if (this.hasStampAttachments) {
        if (!this.$refs.hourReference.$refs.validateForm.validate()) {
          this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.common.validationIssue' })
          return
        }
        this.fileUploadType = 2
        this.$refs.uploader.click()
      } else this.stopTimerHandler()
    },
    stopTimerHandler () {
      if (this.$refs.hourReference.$refs.validateForm.validate()) {
        this.$api.execute('put', `stamptimes/stop/${this.timerObj.id}?datetime=${moment().format('YYYY-MM-DD HH:mm:ss')}`, {})
          .then(response => {
            this.resetTimer()
            this.$root.$emit('snackbar', {
              snackbar: true,
              color: 'success',
              text: 'message.common.updatedSuccess'
            })
          })
      } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.common.validationIssue' })
    },
    resetTimer () {
      if (this.webWorkerInstance) this.webWorkerInstance.terminate()
      this.webWorkerInstance = null
      this.$store.commit('clearTimerValue')
      this.$eventBus.$emit('stopTimer')
      this.time = '00:00:00'
      this.$refs.hourReference.$refs.validateForm.reset()
      this.timerObj = {}
      window.localStorage.setItem('is_timer_running', false)
      this.allAttachments = []
      this.isPictureTakenBefore = false
    },
    saveRecord () {
      this.timerObj.user_id = this.getuserDetails.id
      const model = this.$formatter.cloneVariable(this.timerObj)
      if (this.generalSettings.pause_setting_type !== 3) model.break_hr = 0
      model.start_at = model.start_at ? model.start_at : moment().format('YYYY-MM-DDTHH:mm:ss')
      model.break_hr = moment.duration(parseFloat(model.break_hr), 'minutes').asHours()
      const url = model.id ? `stamptimes/update/${model.id}` : 'stamptimes/start'
      const method = model.id ? 'put' : 'post'
      const msg = model.id ? 'message.common.updatedSuccess' : 'message.common.addedSucess'
      this.$api.execute(method, url, model).then(response => {
        if (method === 'post' && this.hasStampAttachments) this.saveFirstImageWithStampId(response.data.id)
        const model = this.$formatter.cloneVariable(response.data)
        if (model.break_hr) {
          var milliseconds = this.$formatter.getMilliseconds(model.break_hr)
          var duration = moment.duration(milliseconds)
          model.break_hr = parseInt(duration.asMinutes())
        } else model.break_hr = 0
        this.timerObj = this.$formatter.cloneVariable(model)
        this.$store.commit('setTimerObj', response.data)
        if (!this.timerObj.project_id) this.timerObj.project_id = 0
        this.$root.$emit('snackbar', {
          snackbar: true,
          color: 'success',
          text: msg
        })
      })
    },
    saveProject (id, isSetProjectId = true) {
      if (isSetProjectId && id !== undefined && id !== null) this.timerObj.project_id = id
      if (this.timerObj.id) this.saveRecord()
    },
    saveItem () {
      if (this.timerObj.id) this.saveRecord()
    },
    confirmCancel () {
      this.cancelLoading = true
      this.$api.execute('put', `stamptimes/cancel/${this.timerObj.id}`, null)
        .then(() => {
          this.resetTimer()
          this.showCancelDialog = false
        }).finally(() => {
          this.cancelLoading = false
        })
    },
    setDefaultProject () {
      if (this.getCurrentProjects.length > 0) {
        const obj = this.getCurrentProjects && this.getCurrentProjects.length > 0 ? this.getCurrentProjects.find(x => x.number === 'TM001') : null
        if (obj) {
          if (!this.timerObj.project_id) this.saveProject(obj.id)
        }
      }
      return false
    },
    onFileChanged (e) {
      // check for the first / last image
      if (!e.target.files.length > 0) this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'select files' })
      else {
        this.uploadAttachmentsHandler(e.target.files)
      }
    },
    uploadAttachmentsHandler (files) {
      if (files.length < 1) return
      const formData = new FormData()
      for (var i = 0; i < files.length; i++) {
        const file = files[i]
        formData.append(file.name, file)
      }
      let url = ''
      if (this.fileUploadType === 1) url = 'stampattachments/upload_first_image'
      else if (this.fileUploadType === 2 || this.fileUploadType === 3) url = `stampattachments/upload/${this.timerObj.id}`
      this.$api.execute('post', url, formData)
        .then(response => {
          this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'message.timer.uploadSuccess' })
          if (this.fileUploadType === 1) {
            this.isPictureTakenBefore = true
            this.startTimer('')
          } else if (this.fileUploadType === 2 || this.fileUploadType === 3) {
            if (this.fileUploadType === 3) {
              this.getAllFiles()
              this.timerObj.files = []
            }
            if (this.fileUploadType === 2) {
              this.stopTimerHandler()
              this.allAttachments = []
            }
          }
        })
    },
    saveFirstImageWithStampId (id) {
      this.$api.execute('post', `stampattachments/add_first_image/${id}`, null)
        .then(() => {
          this.getAllFiles()
        })
    },
    getAllFiles () {
      this.$api.execute('get', `stampattachments/get_files_by_stamp/${this.timerObj.id}`)
        .then(response => {
          this.allAttachments = response.data
        })
    },
    storeFileChange () {
      this.fileUploadType = 3
      if (this.timerObj.files) this.uploadAttachmentsHandler(this.timerObj.files)
    }
  }
}
</script>
<style lang="css" scoped>
.include_stamp_attachment {
  background-color: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;;
}
</style>
