import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import i18n from './lang/config'
import helper from './mixins/index'
import VueCookie from 'vue-cookie'
import vuetify from './plugins/vuetify'
import eventBus from './plugins/events'
import formatter from './plugins/formatters'
import apiServices from './plugins/apiservices'
import axios from 'axios'
import VueSignaturePad from 'vue-signature-pad'
import VueHighcharts from 'vue-highcharts'
import Highcharts from 'highcharts'
import Sortable from 'sortablejs'
import VueCryptojs from 'vue-cryptojs'
// import AvaliaChecklist from 'avalia_checklist_prod_test'
import { replaceDotWithComma, replaceCommaWithDot, filterDate, filterDateTime, absoluteNumber } from './assets/js/filters'
import './registerServiceWorker'
import exportingInit from 'highcharts/modules/exporting'
exportingInit(Highcharts)

Vue.config.productionTip = false

/* Filters */
const filters = {
  dateFormat: filterDate,
  dateTimeFormat: filterDateTime,
  replaceDotWithComma,
  replaceCommaWithDot,
  absoluteNumber
}
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

const plugins = { formatter, VueCookie, eventBus, apiServices, VueCryptojs }
Object.keys(plugins).forEach(key => {
  Vue.use(plugins[key])
})
Vue.use(VueSignaturePad)

const mixins = { helper }
Object.keys(mixins).forEach(key => {
  Vue.mixin(mixins[key])
})
// Vue.use(AvaliaChecklist)
Vue.use(VueHighcharts, { Highcharts })
axios.defaults.baseURL = process.env.VUE_APP_URL
axios.defaults.headers.common = {
  Authorization: `Bearer ${VueCookie.get(process.env.VUE_APP_TOKEN)}`
}
Vue.directive('sortable', {
  inserted: function (el, binding) {
    // eslint-disable-next-line
    new Sortable(el, binding.value || {})
  }
})
new Vue({
  router,
  store,
  i18n,
  vuetify,
  eventBus,
  render: h => h(App)
}).$mount('#app')
