export const no = {
  message: {
    layout: {
      login: 'Logg inn',
      logout: 'Logg ut',
      dashboard: 'Dashbord',
      timer: 'Time Management',
      projects: 'Prosjekter',
      stemp: 'Stemple',
      calendar: 'Kalender',
      deviation: 'Avvik',
      home: 'Hjem',
      deliver: 'Levere',
      more: 'Mer',
      files: 'Filer',
      equipments: 'Utstyr',
      checklists: 'Sjekklister',
      warehouse: 'Lager',
      orderno: 'Best.nr',
      tasks: 'Oppgaver'
    },
    login: {
      language: 'Språk',
      back: 'Tilbake',
      userName: 'Bruker',
      password: 'Passord',
      authFailed: 'Innlogging feilet !',
      host: 'Domene'
    },
    common: {
      of: 'av',
      install: 'Legg til snarvei på hjemmesiden for hurtig tilgang.',
      justTab: 'Trykk på',
      addToHome: 'deretter <Legg til hjemmeside>',
      english: 'Engelsk',
      norwegian: 'Norsk',
      save: 'Lagre',
      saveAndClose: 'Lagre & lukk',
      send: 'Send',
      edit: 'Edit',
      cancel: 'Avbryt',
      update: 'Oppdater',
      delete: 'Slett',
      updateAndClose: 'Oppdater & lukk',
      close: 'Lukk',
      new: 'ny',
      isActive: 'Is active',
      addNew: 'Legg til ny',
      savedSuccess: 'Saved successfully!',
      addedSucess: 'Lagt til!',
      updatedSuccess: 'Oppdatert!',
      deleteSuccess: 'Slettet!',
      backToList: 'Tilbake', //  til listen
      search: 'Søk',
      yes: 'Ja',
      no: 'Nei',
      isDefault: 'Is Default',
      loadingMsg: 'Loading, Please wait',
      deleteConfirm: 'Vil du slette?',
      areYouSure: 'Er du sikker på at du vil slette?',
      validationIssue: 'Vennligst sjekk de nødvendige feltene!',
      total: 'I dag',
      noResults: 'Ingen treff!',
      others: 'Others',
      typeHere: 'Type here...',
      position: 'Position',
      color: 'Color',
      maxCharacters: 'Max {charCount} characters',
      changedPassword: 'Password Changed Successfully',
      import: 'Import',
      note: 'Note',
      invalidEmail: 'Invalid Email',
      no_records: 'No Records Found!',
      no_data: 'No data available',
      ok: 'Ok',
      date: 'Dato',
      comments: 'Kommentar',
      draftMsg: 'Saved as Draft',
      createProjectRole: 'Create Project Role',
      updateProjectRole: 'Update Project Role',
      module: 'Module',
      selectTemplate: 'Select Template',
      documentTemplate: 'Document Template',
      download: 'Nedlasting',
      uploadedSuccess: 'Uploaded successfully !',
      weekTotalHours: 'Week hrs',
      addWorktype: 'Legg til Arbeidstype',
      updateWorktype: 'Oppdater Arbeidstype',
      worktype: 'Arbeidstype',
      nonbillableworktype: 'Ferie/Fraværstype',
      newUpdate: 'Ny oppdatering tilgjengelig!',
      refresh: 'Oppdater', // Forfriske
      moduleName: 'Module Name',
      updatedOn: 'Updated On',
      columns: 'Columns',
      confirmCancel: 'Confirm Cancel',
      areYouSureCancel: 'Are you sure you want to cancel ?',
      not_approved_notification: 'Hei! {count} timeregistrering(er) i uke {weeknumber} er ikke godkjent',
      hourExceedNotification: 'Registrerte timer overstiger 24 timer.',
      options: 'Options',
      label: 'Label',
      value: 'Value',
      confirmStatusChange: 'Confirm Status change',
      areYouSureToChangeStatus: 'Er du sikker på at du vil endre status til {statusText} ?',
      complete: 'fullstendig',
      incomplete: 'ufullstendig',
      name: 'Navn',
      number: 'Nummer',
      noPermission: 'Sorry! You don\'t have permission to {permissionType} {module}',
      charsLimit35: 'Maksimalt {length} tegn',
      confirmAddHour: 'Confirm to add exceeding hours',
      sureToAddExceedingHour: 'Entered hour is exceeding the Working hours, Are you sure to add ?',
      warning: 'Varsel!',
      required: 'Kreves',
      timerFeatureDisabled: 'Timer feature disabled',
      locationNotSet: 'Location not set',
      type: 'Type',
      changeVideoSource: 'Change video source',
      permissionDenied: 'Kamera tillatelse har blitt nektet! Aktiver det i nettleserinnstillingene!',
      time: 'Time',
      lengthError: 'Lengden må være mindre enn 10',
      update_properties: 'Oppdater egenskaper',
      add_properties: 'Legg til egenskaper',
      preparing_fields: 'Klargjør felt...',
      from_attributs: 'SKJEMAATRIBUTTER',
      enter_title: 'Skriv inn tittelen på hver kolonne:',
      loading_grid: 'Laster rutenett...',
      from_properties: 'FORM EGENSKAPER',
      dragging: 'Dra...',
      catch_up: 'Alle tok igjen!!!',
      add_header: 'Legg til topptekst',
      add_footer: 'Legg til bunntekst',
      drop_fields: 'Slipp feltene her',
      for_header: 'FOR OVERSKRIFT',
      for_footer: 'FOR bunntekst',
      add_html: 'LEGG TIL HTML-INNHOLD',
      relate: 'Forhold',
      unrelate: 'Ikke relatert',
      values: 'Verdier',
      scan: 'Skann',
      filename: 'Filnavn',
      reset: 'Nullstille'
    },
    timer: {
      stop: 'Stop',
      ga_til_time: 'Gå til timeliste',
      minutes: 'minutes',
      start: 'Start',
      place: 'Sted',
      hours: 'Timer',
      activity: 'Aktivitet',
      pause: 'Pause',
      from: 'Fra',
      till: 'Til',
      shift: 'Jobb',
      startTime: 'Starttid',
      endTime: 'Sluttid',
      editHours: 'Rediger',
      approved: 'Godkjent',
      notApproved: 'Ikke godkjent',
      delivered: 'Levert',
      notDelivered: 'Ikke levert',
      year: 'År',
      week: 'Uke',
      nonBillable: 'Ferie/Fravær',
      hourlogging: 'Timelogging',
      noHours: 'Ingenting å levere !',
      deliveredMsg: 'Delivered Successfully',
      addHours: 'Før timer',
      deliverHours: 'Lever timer',
      hoursExceeding: 'Timer Exceeded 8 hours',
      cancelTimer: 'Cancel Timer',
      uploadSuccess: 'Upload Success',
      picture: 'Take a Picture',
      imageNeeded: 'Please upload a picture before you stop the timer!',
      qrCode: 'QR Kode',
      qrQty: 'QR Quantity',
      scanQRCode: 'Skann QR kode',
      attachmentsBelow: 'Finn vedlegg nedenfor'
    },
    projects: {
      startDate: 'Startdato',
      endDate: 'Sluttdato',
      all: 'Alle',
      allActive: 'Alle Aktive',
      myAll: 'Alle mine',
      myActive: 'Mine aktive',
      project: 'Prosjekt',
      customer: 'Kunde',
      status: 'Status',
      noActiveProjects: 'No Active Projects!',
      selectProject: 'Velg Prosjekt',
      projectMaterialDisabled: 'Materialer deaktivert for dette prosjektet'
    },
    calendar: {
      today: 'Today',
      month: 'Month',
      week: 'Week',
      day: 'Day',
      weekNotExists: 'Uke eksisterer ikke',
      yearValid: 'Må være på fire sifre',
      valid: 'Må være gyldig',
      yearTooLessValidation: 'Må være større enn 1920',
      weekLock: 'Valgte datoer er i den låste uken', // Uken er låst !
      dayLocked: 'Valgt dag er i den låste uken', // Day is Locked !
      otherHourEntries: 'Other hour entries'
    },
    shifts: {
      shift: 'Min jobbliste',
      documents: 'Dokumenter:',
      not_found: 'Ingen notater eller dokumenter funnet.'
    },
    deviation: {
      deviations: 'Avvik',
      register_deviation: 'Registrer Avvik',
      high: 'Høy',
      medium: 'Medium',
      low: 'Lav',
      closed: 'Lukket',
      registered: 'Registrert',
      process: 'Behandling',
      actions: 'Handlinger',
      title: 'Tittel',
      reported_by: 'Rapportert av',
      location: 'Avviksområde',
      reason: 'Årsak',
      document: 'Dokument',
      category: 'Avvikskategori',
      estimate_cost: 'Estimert kost',
      severity: 'Alvorlighetsgrad',
      deviation_status: 'Avvik status',
      deadline: 'Tidsfrist',
      description: 'Beskrivelse',
      responsible: 'Ansvarlig',
      copyto: 'Kopi til',
      upload_files: 'Last opp',
      required_message: 'Feltet er obligatorisk',
      consequences: 'Konsekvenser',
      changeRequest: 'Endringsmelding',
      reminder: 'Reminder On',
      deviation_number: 'Deviation Number',
      underlying_reason: 'Underliggende grunn',
      corrective_actions: 'Korrigerende tiltak',
      effect_of_measures: 'Effekt av tiltak',
      view_deviation: 'Vis avvik'
    },
    checklist: {
      title: 'Sjekkliste',
      updateChecklist: 'Oppdater sjekkliste',
      pickCheckList: 'Velg en sjekkliste',
      addCheckList: 'Legg sjekkliste',
      hmsKs: 'HMS/KS',
      templateName: 'Malnavn',
      checklist_recipients: 'E-postmottaker',
      checklist_recipient_hint: 'Skriv inn e-post med komma(,) separert.',
      projectnr: 'PO nr.',
      projectname: 'Prosjektnavn',
      aonr: 'AO nr.',
      workordername: 'AO navn',
      customer: 'Kunde',
      customercontact: 'Kunde kontakt',
      projectleader: 'Prosjektleder',
      importCSV: 'Importer CSV',
      CSVFileInfo: 'Her kan du importere data fra en csv-fil til en sjekkliste.',
      removeRow: 'Fjern 1. rad hvis den har overskrifter.',
      sameOrederColumn: 'Sørg for at kolonnene er i samme rekkefølge som du har definert i sjekklisten.',
      semicolonDelimiter: 'Bruk semikolon som csv-skilletegn.',
      withoutImport: 'Uten import',
      addCSVFile: 'Legg til CSV-fil'
    },
    equipments: {
      equipment: 'Utstyr',
      addEquipment: 'Legge til utstyr',
      updateEquipment: 'Oppdater utstyr',
      viewEquipment: 'Vis utstyr',
      active: 'Aktive',
      quarantine: 'Karantene',
      discarded: 'Discarded',
      warrantydate: 'Garantidato',
      dateofPurchase: 'Kjøpsdato',
      vendor: 'Leverandør',
      modelNumber: 'Modellnummer',
      product: 'Produkt',
      serialNumber: 'Serienummer',
      capacity: 'Kapasitet',
      groups: 'Grupper',
      plannedCalibration: 'Planlagt kalibrering',
      scrapDate: 'Kassert dato',
      equipmentNumber: 'Utstyr nummer',
      placement: 'Plassering',
      notifyByEmail: 'Varsling på e-post',
      mustAllocateToProjectOrLocation: 'Må tilordne utstyret til enten prosjekt eller sted.'
    },
    customModule: {
      modules: 'Moduler',
      customModule: 'Egendefinert modul',
      customModules: 'Egendefinerte moduler',
      uploadDocument: 'Last opp dokument',
      isCompleted: 'Fullført',
      add: 'Legge til',
      urlNotFound: 'URL-en ble ikke funnet',
      completed: 'Fullført',
      notCompleted: 'Ikke fullført',
      fileSizeLimitMsg: 'Filstørrelsesgrensen overskredet for en eller flere filer (maksgrense {maxSize}MB).'
    },
    moduleReports: {
      title: 'Modulrapporter',
      chartyAxis: 'Number Of Records (per module)'
    },
    whsDashboard: { // whs - warehouse
      scanText: 'Skann ordrenummer eller strekkode',
      customerOrder: 'Kundeordre',
      receiveSupplierOrder: 'Motta leverandørordre',
      adjustmentOrders: 'Justeringsordre',
      transferOrders: 'Overføringsordre',
      cycleCount: 'Varetelling',
      product: 'Produkter'
    },
    ccsheet: {
      warehouse: 'Lager',
      created: 'Opprettet',
      counted: 'Telt',
      count: 'Telle',
      reCount: 'Telle om',
      closeComplete: 'Lukk og fullfør',
      unit: 'Enh',
      stock: 'Lager',
      scanLocation: 'Skann eller skriv inn lokasjon',
      nextLoc: 'Neste lok',
      info: 'Melding!',
      continueCount: 'Fortsett å telle',
      clearAndRecount: 'Nullstill og telle om',
      completeRecount: 'Fullfør omtelling',
      ccsheetComplete: 'Telleskjema vil bli fullført. Vil du fortsette?',
      noSheetFound: 'Ingen telleskjema funnet!',
      noProductOnLocation: 'Ingen produkter funnet på denne lokasjonen!',
      stockTake: 'Telling har blitt fullført for denne lokasjonen. Ønsker du å forsette med denne lokasjonen eller nullstille data og telle om igjen?',
      ccsheetAddProduct: 'Produkt ligger ikke i telleskjema. Vil du legge til?'
    },
    product: {
      supplierartno: 'Leverandørens artikkelnummer',
      supplier: 'Leverandørnavn',
      barcode: 'Strekkode',
      noProductFound: 'Ingen produkter funnet!',
      minQty: 'Min.ant.',
      orderQty: 'Best.ant.',
      qty: 'Mottat qty',
      batch: 'Batch Nr',
      scanLoc: 'Velg Lok',
      noLocFound: 'Ingen lokasjon funnet!'
    },
    order: {
      products: 'Produkter',
      avail: 'Benytte',
      ordered: 'Rekke.',
      received: 'Motta.',
      pickedQty: 'Pic.',
      pickOrder: 'Velg ordre',
      delivery: 'levere',
      rest: 'Rest',
      complete: 'Ferdig',
      pick: 'Hente',
      scanProduct: 'Skann strekkode for et produkt',
      scanBatch: 'Skann batchnummer',
      picked: 'Hentet',
      toPick: 'Skal hentes',
      location: 'Lok',
      pickLocation: 'Velg lokasjon',
      qtyExceeds: 'Antall overskrider',
      noOrderFound: 'Ingen ordre funnet!',
      notValidBatch: 'Batchnummer er ugyldig!',
      receiveOrder: 'Motta ordre',
      receive: 'Mottat',
      batchExists: 'Batchnummer eksisterer allerede!',
      receiveWarning: 'Vil du legge til {qty} stk {product} på lager?',
      completedSuccess: 'Completed successfully!',
      addFreight: 'Vil du legge til frakt ?',
      cost: 'Kostnad',
      price: 'Pris',
      orderedQtyHigh: 'Antall mottatt er høyere enn bestilt, vil du fortsette?  ( Ja / Nei )',
      collect: 'samle inn',
      receiving: 'Receiving',
      availableToPick: 'Avail qty(s) to receive',
      adjust: 'Adjust',
      orderComplete: 'Order will be completed. Continue?'
    },
    materials: {
      title: 'Materials',
      pickProducts: 'Velg produkter'
    },
    tasks: {
      forYour: 'For din',
      information: 'Informasjon',
      acknowledge: 'Bekreft',
      review: 'Revider',
      approval: 'Godkjenning',
      subject: 'Emne',
      dueDate: 'Due date',
      approve: 'Godkjenn',
      disapprove: 'Ikke godkjenn',
      keep: 'Beholde',
      remove: 'Fjerne'
    },
    mixed: {
      scanSerialNumber: 'Skann serienummer',
      noEquipmentFound: 'Ingen utstyr funnet',
      sharedUrl: 'Delt URL',
      checklistfiles: 'Sjekklistefiler',
      attachments: 'Vedlegg',
      signature: 'Signatur',
      nochecklists: 'Ingen sjekklister',
      checklistName: 'Sjekkliste Navn',
      noOfDocuments: 'Nei av dokumenter',
      documentTitle: 'Dokument tittel',
      qrCode: 'QR Code',
      qrQty: 'QR Quantity',
      attachmentsBelow: 'Finn vedlegg nedenfor'
    }
  }
}
