export const SET_AUTH = 'setAuth'
export const RESET_AUTH = 'resetAuth'
export const SET_PAGINATION = 'savePagination'
export const SET_LISTVALUES = 'setListValues'
export const SHOW_DELETE_DIALOG = 'showDeleteDialog'
export const HIDE_DIALOG = 'hideDialog'
export const SHOW_LOADER = 'showLoader'
export const HIDE_LOADER = 'hideLoader'
export const SUPPORTING_LIST = 'supportingList'
export const CHANGES_LOST_DIALOG = 'changeLostDialog'
export const IS_CHANGES_MADE = 'isChangesMade'
export const REMEMBER_LIST = 'saveFilter'
export const SHAREPPOINT_FIELDS = 'setSharepointFeilds'
export const SET_TIMER_VALUE = 'setTimerValue'
export const CLEAR_TIMER_VALUE = 'clearTimerValue'
export const SET_TIMEROBJ = 'setTimerObj'
export const SITE_NEW_CONTENT = 'siteNewContent'
export const IS_MONTHLOCKED = 'isMonthLocked'
export const SHOWTOTAL_LOGSON_SINGLEDAY = 'showTotalLogsOnSingleDay'
export const SET_PERMISSIONS = 'setPermissions'
export const RESET_PERMISSIONS = 'resetPermissions'
export const SHOW_CONFIMATION_DIALOG = 'showConfirmationDialog'
export const HIDE_CONFIMATION_DIALOG = 'hideConfirmationDialog'
export const SCANNED_VALUE = 'scannedValue'
