import moment from 'moment'
const replaceDotWithComma = (value) => {
  if (value === null || value === undefined || value === '') return ''
  return parseFloat(value).toFixed(2).toString().replace('.', ',')
}
const replaceCommaWithDot = (value) => {
  if (!value) return ''
  return value.toString().replace(',', '.')
}
const absoluteNumber = (value) => {
  if (value === null || value === undefined || value === '') return '0'
  return value.toString().indexOf('.') === -1 ? parseFloat(value).toString() : parseFloat(value).toFixed(2).toString().replace('.', ',')
}

const filterDate = (date) => {
  if (!date) return null
  return moment(date, 'DD.MM.YYYY').isValid() ? moment(date, 'DD.MM.YYYY').format('DD.MM.YYYY') : null
}

const filterDateTime = (date) => {
  if (!date) return null
  return moment(date, 'DD.MM.YYYY HH:mm:ss').isValid() ? moment(date, 'DD.MM.YYYY  HH:mm:ss').format('DD.MM.YYYY  HH:mm:ss') : null
}

export { replaceDotWithComma, replaceCommaWithDot, filterDate, filterDateTime, absoluteNumber }
