import moment from 'moment'
export default {
  methods: {
    getRunningStemp () {
      this.$store.dispatch('getRunningTimer', this.getuserDetails.id)
        .then((data) => {
          if (data) {
            const model = this.$formatter.cloneVariable(data)
            if (model.break_hr) {
              var milliseconds = this.$formatter.getMilliseconds(model.break_hr)
              var duration = moment.duration(milliseconds)
              model.break_hr = parseInt(duration.asMinutes())
            } else model.break_hr = 0
            this.timerObj = this.$formatter.cloneVariable(model)
            /* for include_stamp_attachment */
            if (this.hasStampAttachments && this.timerObj.id) {
              this.isPictureTakenBefore = true
              this.getAllFiles()
            }
            /* end */
            if (!this.timerObj.project_id) this.timerObj.project_id = 0
            if (data.time) {
              this.startTimer(data.time)
              this.isTimerRunning = true
              this.$store.state.common.isTimerRunning = true
            }
          } else if (this.$route.path === '/stemp') {
            let pinnedProject = window.localStorage.getItem('stempPinnedProject')
            pinnedProject = pinnedProject ? JSON.parse(pinnedProject) : null
            setTimeout(() => {
              if (pinnedProject && pinnedProject.project_id && (pinnedProject.user_id === this.getuserDetails.id)) this.timerObj.project_id = pinnedProject.project_id || 0
            }, 50)
          }
        })
    },
    startTimer (time) {
      if (!time) time = new Date()
      if (typeof (Worker) !== 'undefined') {
        if (this.webWorkerInstance === null) {
          this.webWorkerInstance = new Worker(`../../js/timer.js?date=${time}`)
        }
        this.webWorkerInstance.onmessage = (event) => {
          this.time = event.data
        }
        if (this.$route.path === '/stemp') {
          this.$store.commit('setTimerValue', this.time)
          window.localStorage.setItem('is_timer_running', true)
          if (!this.timerObj.id) this.saveRecord()
        }
      } else {
        alert('Browser is not supporting web workers!')
      }
    }
  }
}
