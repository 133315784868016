export const LOGIN = 'login'
export const LOGOUT = 'logout'
export const PAGINATION = 'rememberPagination'
export const USEROLES = 'userRoles'
export const DELETE_HANDLER = 'deleteHandler'
export const DELETE_SINGLE_RECORD_HANDLER = 'deleteSingleRecordHandler'
export const USERS = 'getUsers'
export const SHIFTS = 'getShifts'
export const PROJECTS = 'getProjects'
export const WORKTYPES = 'getWorktypes'
export const ACTIVITIES = 'getActivities'
export const GET_RUNNING = 'getRunningTimer'
export const GETCURRENTPROJECTS = 'getCurrentProjects'
export const RELOAD_NEW_CONTENT = 'reloadNewContent'
export const CHECKLIST_FORMS = 'getChecklistForms'
export const ACCOUNTS_LIST = 'getAccounts'
export const USER_GROUPS = 'getUserGroups'
export const GENERAL_SETTINGS = 'getGeneralSettings'
export const CHECK_MONTHLOCKED_STATUS = 'checkMonthLockedStatus'
export const GETALLTHE_LOGSFOR_SINGLEDAY = 'getAllTheLogsForSingleDay'
export const SHOWALLTHE_LOGSON_DAY = 'showAllTheLogsOnDay'
